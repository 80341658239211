/*!

  =========================================================
  * Vue Paper Dashboard 2 PRO - V2.4.0
  =========================================================

  * Product Page: https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Available with purchase of license from https://www.creative-tim.com/product/vue-paper-dashboard-2-pro
  * Copyright 2019 Creative Tim (https://www.creative-tim.com)
  * License Creative Tim (https://www.creative-tim.com/license)

  =========================================================

*/

import Vue from 'vue'
import './pollyfills'
import VueRouter from 'vue-router'
import VueNotify from 'vue-notifyjs'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import App from './App.vue'
import { store } from './store';
import axios from 'axios';

// Axios setup
axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://ares-dev.mundialmedia.com/api/';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.timeout = 3600000;
axios.defaults.validateStatus = () => { return true };

// Plugins
import GlobalComponents from './globalComponents'
import GlobalDirectives from './globalDirectives'
import SideBar from './components/UIComponents/SidebarPlugin'
import initProgress from './progressbar';

// router setup
import routes from './routes/routes'

// library imports
import './assets/sass/paper-dashboard.scss'
import './assets/sass/demo.scss'
import sidebarLinks from './sidebarLinks'
import './registerServiceWorker'

// plugin setup
Vue.use(VueRouter)
Vue.use(GlobalDirectives)
Vue.use(GlobalComponents)
Vue.use(VueNotify)
Vue.use(SideBar, {sidebarLinks: sidebarLinks})
locale.use(lang)

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})
initProgress(router);

const tienda = store;
router.beforeResolve((to, from, next) => {

  //console.log({to, from, next})

  if (to.name == 'NewPass') {
    const resetDict = {}, resetKeys = Object.keys(to.query);
    resetDict.name = to.name

    //console.log({resetKeys})

    // Requires credentials to proceed properly
    if (resetKeys.length) {
      for (let r = 0; r < resetKeys.length; r++) {
        const tmpKey = resetKeys[r],
        tmpVal = to.query[tmpKey];

        //console.log({tmpKey, tmpVal})

        resetDict[tmpKey] = tmpVal

        //console.log({resetDict})
      }
    }

    //console.log({resetDict})

    if (!tienda.reset_pass) tienda.reset_pass = resetDict
    //if (!tienda.mmData) tienda.mmData = true
  }

  if (to.name !== 'Login' && !tienda.mmData) next({ name: 'Login' })
  else next()
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  store,
  router
})
