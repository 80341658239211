<template>
  <div>
    <ValidationObserver v-slot="{ invalid }" ref="observer" tag="form" @submit.prevent="getSegmentInsights()">
    <div v-if="store.mmData" class="row mundialBorders mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-6">
        <div class="form-group">
          <ValidationProvider
              name="date_range"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Date Range"
              name="date_range"
              autocomplete="off"
              v-model="store.mmDateRange"
              :error="failed ? 'Date Range field is required': null"
              :hasSuccess="passed"
            >
              <el-date-picker v-model="store.mmDateRange" type="daterange"
                          class="daterange-mm"
                          :start-placeholder="startDate"
                          :end-placeholder="endDate"
                          :default-value="startDate"
                          :picker-options="pickerOptions1">
               </el-date-picker>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="reach"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Granularity" v-if="store.mmData"
                name="reach"
                autocomplete="off"
                v-model="store.reachSelected"
                :error="failed ? 'Reach Size is required': null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default"
                       size="large"
                       v-model="store.reachSelected"
                       placeholder="Required *">
                  <el-option v-for="option in [{value:1,label:'Broad'}]"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="demographic"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Audience" v-if="store.mmData"
                name="demographic"
                autocomplete="off"
                v-model="store.demoSelected"
                :error="failed ? 'Demographic field is required': null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default" multiple
                       size="large"
                       v-model="store.demoSelected"
                       placeholder="Required *">
                  <el-option v-for="option in store.mmData.demo_options"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <div v-if="store.mmData" class="row mundialBorders mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-6">
        <div class="form-group">
          <ValidationProvider
              name="campaign"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Campaign" v-if="store.mmData"
                name="campaign"
                autocomplete="off"
                v-model="store.campaignSelected"
                :error="failed ? 'Campaign is required' : null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default"
                       size="large"
                       v-model="store.campaignSelected"
                       placeholder="Required *">
                  <el-option v-for="option in store.mmData.campaign_options.filter(option => mappedCampaignSet.has(option.value) && option.archive_flag !== 1 && option.ignore_flag !== 1)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-6">
        <div class="form-group">
          <ValidationProvider
              name="placements"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Placements" v-if="store.mmData"
                name="placements"
                autocomplete="off"
                v-model="store.placementSelected"
                :error="failed ? 'Placements field is required': null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default" multiple
                       size="large"
                       v-model="store.placementSelected"
                       placeholder="Required *">
                  <el-option v-for="option in [{value:'all',label:'All'}].concat(placementOptions)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <div v-if="store.mmData" class="row mundialBorders mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="keywords"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Segment List" v-if="store.mmData"
                name="keywords"
                autocomplete="off"
                v-model="store.csvFile"
                :error="failed ? 'Segment keywords are required': null"
                :hasSuccess="passed"
            >
              <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="handleFileUpload" v-model="store.csvFile"/>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-3 mundial-vcenter">
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-6">
        <div class="form-group" v-if="store.csvFile.length">
            <fg-input label="Preview" v-if="store.mmData"
                name="keywords"
                autocomplete="off"
                v-model="store.keywordsInput"
                :disabled="true"
            >
              <textarea class="form-control" placeholder="Please input segment keywords" rows="3" v-model="store.keywordsInput" disabled></textarea>
            </fg-input>
        </div>
      </div>
    </div>

    <div v-if="store.mmData" class="row mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-2 mundial-vcenter">
        <button @click.prevent="resetFilters()" class="btn btn-default btn-fill btn-wd btn-mundial-reset" v-if="false">Reset</button>
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-8 mundial-vcenter">
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-2 mundial-costmap-vcenter">
        <button @click.prevent="getSegmentInsights()" class="btn btn-success btn-fill btn-wd btn-mundial-success" :disabled="invalid">Retrieve</button>
      </div>
    </div>
    </ValidationObserver>

    <!-- iab breakout table -->
    <iab-breakout-tables
      :TABLE="TABLE2"
      :renderFlag="renderFlag"
      :iabBreakoutList="iabBreakoutList"
      :resetFilters="resetFilters"
    >
    </iab-breakout-tables>

    <div v-if="store.mmData && renderFlag" class="row mundialHeader">
      <h4 class="title col-md-12">Matching Sites</h4>
      <div v-if="!store.mmData.user.display_tc" class="col-md-3 mundial-vcenter" v-for="(item, index) in store.mmData.iab_matching_site_ids" :key="index">
        {{ store.idSnExtDict[item.site_id] }}
      </div>
    </div>

    <!-- segment insights table -->
    <segment-insights-tables
      :TABLE="TABLE"
      :renderFlag="renderFlag"
      :segmentInsightsList="segmentInsightsList"
      :ogSegmentInsightsList="ogSegmentInsightsList"
      :resetFilters="resetFilters"
    ></segment-insights-tables>
  </div>
</template>
<script>
  import { DatePicker } from 'element-ui'
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import SegmentInsightsTables from 'src/components/Dashboard/Views/Tables/SegmentInsightsTables.vue'
  import IabBreakoutTables from 'src/components/Dashboard/Views/Tables/IabBreakoutTables.vue'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);  

  // Filter variables
  const mmDate = store.mmDate(),
  maxDate = store.mmDate(),
  maxDateThresh = store.mmDate(),
  minDateThresh = new Date(store.mmData.active_month),
  storeNameDict = {
    'date': 'mmDateRange',
    'product_type': 'productSelected',
    'product_subtype': 'subProductSelected',
    'billing_entity': 'companySelected',
    'site_name': 'siteSelected',
  },
  lastSeven = mmDate.GetLastSeven(),
  sDate = lastSeven['startDay'].toISOString().split('T')[0],
  eDate = lastSeven['endDay'].toISOString().split('T')[0],
  TABLE = 'segmentInsightsTable',
  TABLE2 = 'iabBreakoutTable';

  // Default daterange thresholds
  //maxDateThresh.setTime(maxDateThresh.getTime() - 3600 * 1000 * 24);
  maxDateThresh.setTime(maxDateThresh.getTime() + 3600 * 1000 * 24 * 45);

  console.log({minDateThresh,maxDateThresh})

  export default {
    components: {
      SegmentInsightsTables,
      IabBreakoutTables,
      Modal,
      [Button.name]: Button,
      [DatePicker.name]: DatePicker,
    },
    data () {
      return {
        TABLE,
        TABLE2,
        segmentInsightsList: [],
        ogSegmentInsightsList: [],
        iabBreakoutList: [],
        iabBreakoutDict: {},
        store,
        renderFlag: false,
        pickerOptions: {
          disabledDate (date) {
            let boolCheck = false;
            if (date > maxDateThresh) boolCheck = true
            if (date < minDateThresh) boolCheck = true
            
            //return date > maxDateThresh || date < minDateThresh
            return boolCheck
          }
        },
        pickerOptions1: {
          disabledDate (date) {
            if (store.mmData && store.mmData.user.is_staff) {
              return date > maxDateThresh
            } else {
              let boolCheck = false;
              if (date > maxDateThresh) boolCheck = true
              if (date < minDateThresh) boolCheck = true

              return boolCheck
            }
          },
          shortcuts: [{
            text: 'MTD',
            onClick (picker) {
              const monthToDate = mmDate.GetMonthToDate(),
              sDate = monthToDate['startDay'],
              eDate = monthToDate['endDay'] > maxDate ? maxDate : monthToDate['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'MTD'
            }
          },
          {
            text: 'Last 7 Days',
            onClick (picker) {
              const lastSeven = mmDate.GetLastSeven(),
              sDate = lastSeven['startDay'],
              eDate = lastSeven['endDay'] > maxDate ? maxDate : lastSeven['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'Last 7 Days'
            }
          },
          {
            text: 'Last 30 Days',
            onClick (picker) {
              const lastThirty = mmDate.GetLastThirty(),
              sDate = lastThirty['startDay'],
              eDate = lastThirty['endDay'] > maxDate ? maxDate : lastThirty['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'Last 30 Days'
            }
          },
          {
            text: 'Last Week',
            onClick (picker) {
              const lastWeek = mmDate.GetLastWeek(),
              sDate = lastWeek['startDay'],
              eDate = lastWeek['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'Last Week'
            }
          },
          {
            text: 'Last Month',
            onClick (picker) {
              const lastMonth = mmDate.GetLastMonth(),
              sDate = lastMonth['startDay'],
              eDate = lastMonth['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'Last Month'
            }
          }]
        },
      }
    },
    computed: {
      mappedCampaignSet () {
        const campaignIDSet = !store.mmData.mapped_campaign_list ?
          new Set([]) :
          Array.isArray(store.mmData.mapped_campaign_list) ?
          new Set(store.mmData.mapped_campaign_list)
          : new Set([parseInt(store.mmData.mapped_campaign_list)]);

        console.log({campaignIDSet})

        return campaignIDSet
      },
      startDate () {
        return store.mmDateRange.length ? store.mmDateRange[0] : ''
      },
      endDate () {
        return store.mmDateRange.length ? store.mmDateRange[1] : ''
      },
      placementOptions () {
        const campaignSelected = store.campaignSelected === 'all' ? false : store.mmData.campaign_options.filter(obj => obj.value === store.campaignSelected)[0],
        campaign_id = campaignSelected ? campaignSelected.value : false,
        placement_list = store.mmData.campaign_placement_dict.filter(obj => obj.campaign_id === campaign_id),
        campaign_placement_set = campaign_id && placement_list.length ? new Set(placement_list[0].placement_id.split(',')) : false;

        console.log({campaignSelected, campaign_id, placement_list, campaign_placement_set})

        return campaign_placement_set ? store.mmData.placement_options.filter(obj => campaign_placement_set.has(obj.value.toString())) : store.mmData.placement_options
      },
    },
    methods: {
      resetFilters () {
        // Update global
        store.mmDateRange = [sDate, eDate]
        store.reachSelected = 1
        store.demoSelected = ''
        store.campaignSelected = ''
        store.placementSelected = ['all']
        store.keywordsInput = ''
        store.csvFile = ''
        store.mmData.segment_insights_list = false
        store.mmCustomCSVHeaders[TABLE] = {}
        store.mmText = 'Segment Insights'

        // Update local
        this.renderFlag = false;
      },
      handleFileUpload(event) {
        store.mmUploadCSV(event)
      },
      handleMoney(row, prop) {
        const tmpVal = row[prop],
        newVal = !isNaN(parseInt(tmpVal)) ? new Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD'}).format(tmpVal) : '';

        //console.log({newVal})

        return newVal == 'NaN' ? '' : newVal
      },
      handleNumbers(row, prop) {
        const tmpVal = row[prop],
        newVal = !isNaN(parseInt(tmpVal)) ? Number(tmpVal).toLocaleString("en-US") : '';

        //console.log({newVal})

        return newVal == 'NaN' ? '' : newVal
      },
      stringifyRows (dataRows) {
        const cleanRows = [], tableRows = JSON.parse(JSON.stringify(dataRows)),
        tableLen = tableRows.length,
        handleMoney = this.handleMoney, handleNumbers = this.handleNumbers;

        for (let r = 0; r < tableLen; r++) {
          const tmpRow = tableRows[r];

          // Convert to strings
          tmpRow.cpm_cost = handleMoney(tmpRow, 'cpm_cost')
          tmpRow.mg_daily_imp = handleNumbers(tmpRow, 'mg_daily_imp')
          tmpRow.mg_monthly_imp = handleNumbers(tmpRow, 'mg_monthly_imp')
          tmpRow.mc_daily_adreq = handleNumbers(tmpRow, 'mc_daily_adreq')
          tmpRow.mc_monthly_adreq = handleNumbers(tmpRow, 'mc_monthly_adreq')

          cleanRows.push(tmpRow)
        }

        return cleanRows
      },
      generateRandomArray(length, targetSum) { // SAMPLE DATA
        const randomArray = Array.from({ length }, () => Math.random()); // Generate random numbers
        const sum = randomArray.reduce((acc, num) => acc + num, 0);

        // Scale and floor values
        const scaledArray = randomArray.map(num => Math.floor(num / sum * targetSum));

        // Adjust the last element to fix rounding errors
        scaledArray[length - 1] += targetSum - scaledArray.reduce((acc, num) => acc + num, 0);

        console.log({scaledArray})

        return scaledArray;
      },
      async getSegmentInsights () {
        this.renderFlag = false;

        // Segment Insights variables
        const dateRange = [store.mmDateRange[0], store.mmDateRange[1]],
        reachSelected = store.reachSelected,
        demoSelected = store.demoSelected,
        campaignSelected = store.campaignSelected,
        placementSelected = store.placementSelected,
        keywordsInput = store.keywordsInput,
        tmpMin = new Date(dateRange[0]),
        tmpMax = dateRange[1] ? new Date(dateRange[1]) : false,
        adjMin = new Date(tmpMin.getTime()).toISOString().split('T')[0],
        adjMax = tmpMax ? new Date(tmpMax.getTime()).toISOString().split('T')[0] : false,
        queryMin = new Date(tmpMin.getTime() - 3600 * 1000 * 24 * 1).toISOString().split('T')[0],
        queryMax = tmpMax ? new Date(tmpMax.getTime() + 3600 * 1000 * 24 * 1).toISOString().split('T')[0] : false,
        queryDateRange = queryMax ? [queryMin, queryMax] : [queryMin],
        fileReader = new FileReader();

        // Adjust dates
        dateRange[0] = adjMin;
        dateRange[1] = tmpMax ? adjMax : adjMin;
        if (adjMax) dateRange[1] = adjMax;

        console.log({dateRange, reachSelected, demoSelected, campaignSelected, placementSelected, keywordsInput, queryDateRange});

        //
        // API call
        //
        const boolCheck = await store.getMundialSegmentInsight({dateRange, reachSelected, demoSelected, campaignSelected, placementSelected, keywordsInput, queryDateRange});
        if (boolCheck) {
          // Needed if rows need cleaning
          //this.segmentInsightsList = await this.stringifyRows(store.mmData.segment_insights_list)

          this.iabBreakoutDict = store.mmData.iab_breakout_dict

          // May need to reinclude
          //this.segmentInsightsList = store.mmData.segment_insights_list
          //this.ogSegmentInsightsList = JSON.parse(JSON.stringify(this.segmentInsightsList))
        }

        console.log({boolCheck, iabBreakoutDict:this.iabBreakoutDict});

        if (boolCheck) {
          console.log({iabBreakoutDict:this.iabBreakoutDict})

          const iabBreakoutArr = Object.entries(this.iabBreakoutDict).map(([key, value]) => ({
            segment:key.trim(),
            iab:value.map(subArr => `${subArr[0]} (${subArr[1]}%)`).join('; '),
          })).sort((a, b) => a.segment.localeCompare(b.segment));

          console.log({iabBreakoutArr})

          this.iabBreakoutList = iabBreakoutArr
        }
        // END API



        //
        // SAMPLE DATA
        //
        const boolCheck2 = keywordsInput.length ? keywordsInput.split(',') : false,
        boolLen = boolCheck2 ? boolCheck2.length : boolCheck2;
        //if (boolCheck2) {
        if (false) {
          const demoArr = Array.isArray(demoSelected) ? demoSelected : [demoSelected],
          demoLen = demoArr.length, randomIntsArr = this.generateRandomArray(boolLen * demoLen, 100),
          mathFloor = Math.floor, mathRandom = Math.random,
          iabBreakoutDict = {};
          let sampleArr = [],
          masterIdx = 0;

          for (let i = 0; i < boolLen; i++) {
            const tmpKW = boolCheck[i];

            // IAB Breakout
            if (!iabBreakoutDict.hasOwnProperty(tmpKW)) iabBreakoutDict[tmpKW] = 0

            for (let j = 0; j < demoLen; j++) {
              const tmpDemo = demoArr[j],
              tmpPerc = randomIntsArr[masterIdx];

              // IAB Breakout
              if (iabBreakoutDict.hasOwnProperty(tmpKW)) iabBreakoutDict[tmpKW] += tmpPerc
  
              sampleArr.push(
                {
                  segment_id:tmpKW,
                  placement_id:'',
                  demo_id:store.idDemoDict[tmpDemo],
                  traffic_perc:`${tmpPerc}%`,
                  ctr:`${mathFloor(mathRandom() * 10) + 1}%`,
                  vcr:`${mathFloor(mathRandom() * (60 - 45 + 1)) + 45}%`,
                  viewability:`${100 - (mathFloor(mathRandom() * 30) + 1)}%`
                }
              )
              masterIdx++
            }
          }

          console.log({iabBreakoutDict})

          const iabBreakoutArr = Object.entries(iabBreakoutDict).map(([key, value]) => ({
            iab:key.trim(),
            perc:`${value}%`
          })).sort((a, b) => a.iab.localeCompare(b.iab));

          console.log({iabBreakoutArr})

          this.iabBreakoutList = iabBreakoutArr

          // SAMPLE
          //this.segmentInsightsList = sampleArr
          //this.ogSegmentInsightsList = JSON.parse(JSON.stringify(this.segmentInsightsList))
        }
        //
        // END SAMPLE
        //

        // Update render flag
        this.renderFlag = true;

        // Populate custom CSV headers
        store.mmCustomCSVHeaders[TABLE] = {}
        store.mmCustomCSVHeaders[TABLE]['Date Range'] = dateRange
        store.mmCustomCSVHeaders[TABLE]['Granularity'] = store.idReachDict[reachSelected]
        store.mmCustomCSVHeaders[TABLE]['Audience'] = Array.isArray(demoSelected) ? demoSelected.map(x => store.idDemoDict[x]) : store.idDemoDict[demoSelected]
        store.mmCustomCSVHeaders[TABLE]['Segments'] = keywordsInput
        store.mmCustomCSVHeaders[TABLE]['Campaign'] = store.idCampaignDict[campaignSelected]
        store.mmCustomCSVHeaders[TABLE]['Placements'] = Array.isArray(placementSelected) && !placementSelected.includes('all') ?
          placementSelected.map(x => store.idPlacementDict[x]) :
          Array.isArray(placementSelected) && placementSelected.includes('all') ?
          store.idPlacementDict['all'] :
          store.idPlacementDict[placementSelected]

        // Populate custom CSV headers
        store.mmCustomCSVHeaders[TABLE2] = JSON.parse(JSON.stringify(store.mmCustomCSVHeaders[TABLE]))
      },
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false

      //
      // Set Dashboard Defaults
      //
      store.mmDateRange = [sDate, eDate]
      store.reachSelected = 1
      store.demoSelected = ''
      store.campaignSelected = ''
      store.placementSelected = ['all']
      store.keywordsInput = ''
      store.csvFile = ''
      store.mmText = 'Segment Insights'
      //this.resetFilters()
    },
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
  .date-mundial {
    border-radius: 12px !important;
    border: 1px solid #ddd !important;
  }
  .date-mundial > .el-input__inner {
    border-radius: 10px !important;
  }
  .mundial-costmap-vcenter > .btn-mundial-success {
    float: right;
  }
  .form-group input[type=file] {
    opacity: 100;
    position: relative;
  }
  input#file-upload-button {
    padding-right: 10rem !important;
  }
</style>
