<template>
    <div>
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(editRow)" autocomplete="off">

        <div class="row">
          <div class="col-md-12">
            <ValidationProvider
              name="campaign"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'Campaign Name must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="campaign"
                      label="Common Name"
                      placeholder="Common Name"
                      v-model="editValue.common_name">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <ValidationProvider
              name="start_date"
              rules="required"
              v-slot="{ passed, failed }"
            >
              <fg-input label="Start Date (Required *)"
                name="start_date"
                autocomplete="off"
                v-model="editValue.start_date"
                :error="failed ? 'Start Date field is required': null"
                :hasSuccess="passed"
              >
                <el-date-picker v-model="editValue.start_date" type="date"
                          class="date-mundial"
                          :picker-options="pickerOptions">
                </el-date-picker>
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-md-6">
            <fg-input label="End Date"
                name="end_date"
                autocomplete="off"
                v-model="editValue.end_date"
            >
              <el-date-picker v-model="editValue.end_date" type="date"
                          class="date-mundial"
                          :picker-options="pickerOptions">
              </el-date-picker>
            </fg-input>
          </div>
          <div class="col-md-2">
          </div>
        </div>


        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd">
            Edit
          </button>
          <button type="button" class="btn btn-default btn-fill btn-wd" @click="toggleEditModal">
            Cancel
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
  </div>
</template>
<script>
  import { DatePicker } from 'element-ui';
  import { Button } from 'src/components/UIComponents';
  import { store } from 'src/store';
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  // Filter variables
  const mmDate = store.mmDate(),
  maxDate = store.mmDate(),
  maxDateThresh = store.mmDate(),
  minDateThresh = new Date(store.mmData.active_month),
  lastSeven = mmDate.GetLastSeven(),
  sDate = lastSeven['startDay'].toISOString().split('T')[0],
  eDate = lastSeven['endDay'].toISOString().split('T')[0];

  // Export
  export default {
    props: ['toggleEdit','editValue','OGValue'],
    components: {
      [Button.name]: Button,
      [DatePicker.name]: DatePicker,
    },
    data () {
      return {
        store,
        pickerOptions: {
          disabledDate (date) {
            let boolCheck = false;
            if (date > maxDateThresh) boolCheck = true
            if (date < minDateThresh) boolCheck = true

            //return date > maxDateThresh || date < minDateThresh
            return boolCheck
          }
        },
      }
    },
    computed: {
      tableColumns () {
        return store.tableColumns
      },
    },
    methods: {
      toggleEditModal() {
        this.editValue.common_name = ''
        this.editValue.start_date = ''
        this.editValue.end_date = ''
        this.toggleEdit()
      },
      async editRow () {
        const userDetails = {};
        if (this.editValue.common_name !== this.OGValue.common_name) userDetails.common_name = this.editValue.common_name
        if (this.editValue.start_date !== this.OGValue.start_date) userDetails.start_date = this.editValue.start_date instanceof Date ? this.editValue.start_date.toISOString().split('T')[0] : this.editValue.start_date
        if (this.editValue.end_date !== this.OGValue.end_date) userDetails.end_date = this.editValue.end_date instanceof Date ? this.editValue.end_date.toISOString().split('T')[0] : this.editValue.end_date
        userDetails.id = this.editValue.id

        console.log('Your data: ' + JSON.stringify(userDetails))

        const boolCheck = await store.updateMundialCampaign(userDetails)
        if (boolCheck === 'EXISTING_UNIQUE') {
            alert('CAMPAIGN already exists!')
        } else if (boolCheck) {
            this.toggleEdit()
        } else {
            alert('CAMPAIGN is incorrect!')
        }
      },
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
    },
  }

</script>
<style>

</style>
