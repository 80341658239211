<template>
  <div v-if="!store.mmData.user.display_tc" class="row">
    <div class="col-md-12">
      <h4 class="title">Trailing Report: {{store.mmText}}</h4>
      <p class="category mmWarning">
        * ALL NUMBERS ARE PRELIMINARY AND SUBJECT TO CHANGE.
      </p>
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">
          {{ MUNDIAL_PLATFORM }} performance table
          <button id="exportMM" v-if="queriedData.length" class="btn btn-badge btn-round btn-warning pull-right" @click="() => store.exportMundial(searchQuery, TABLE)">
            export csv <i class="nc-icon nc-cloud-download-93"></i>
          </button>          
        </div>
      </div>
      <div class="card-body row body-mm">
        <div class="col-sm-2">
          <div class="form-group">
            <fg-input label="Aggregation" v-if="store.mmData">
              <el-select class="select-default mm-default"
                @change="setSummaryData()"
                  size="large"
                  v-model="store.aggregateSelected"
                  placeholder="Total">
                  <el-option v-for="option in aggregateOptions"
                    class="select-default"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="col-sm-2">
          <div class="form-group">
            <fg-input label="Rows" v-if="store.mmData">
              <el-select
                class="select-default mm-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </fg-input>
          </div>
        </div>
        <div class="col-sm-6">
          <!-- empty -->
        </div>
        <div class="col-sm-2">
          <div class="pull-right">
            <fg-input class="input-sm search-mm"
                      placeholder="Search"
                      v-model="searchQuery"
                      addon-right-icon="nc-icon nc-zoom-split">
            </fg-input>
          </div>
        </div>
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped"
                    :data="queriedData"
                    border
                    style="width: 100%">
            <el-table-column v-for="column in tableColumns"
                             sortable
                             :align="column.align"
                             :key="column.label"
                             :min-width="store.colWidthDict[column.prop]"
                             :prop="column.prop"
                             :label="column.label">
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-6 pagination-info">
          <p class="category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
        </div>
        <div class="col-sm-6">
          <p-pagination class="pull-right"
                        v-model="pagination.currentPage"
                        :per-page="pagination.perPage"
                        :total="pagination.total">
          </p-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import { store } from 'src/store'
  import { mundialCols, adminCols, adminSearch, clientCols, clientSearch } from './MundialColumns'
  import users from './users'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  const TABLE = 'mundialTables';

  export default{
    props: ['setSummaryData','aggregateOptions'],
    components: {
      PPagination
    },
    computed: {
      tableColumns () {
        return this.store.tableColumns
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        const propsToSearch = this.store.propsToSearch[TABLE],
        lowerSearchQuery = this.searchQuery.toLowerCase()
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of propsToSearch) {
              let rowValue = row[key].toString().toLowerCase()
              if (rowValue.includes && rowValue.includes(lowerSearchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      generateSummary () {
        const mundialData = JSON.parse(JSON.stringify(this.store.mundialData)),
          keyArr = Object.keys(mundialData[0]),
          keySet = new Set(keyArr),
          dimsArr = this.store.ogPropsToSearch[TABLE] ? this.store.ogPropsToSearch[TABLE] : this.store.propsToSearch[TABLE],
          dimensionSet = new Set(dimsArr),
          metricSet = new Set([...keySet].filter(x => !dimensionSet.has(x)));

        let tableRows = [];
        switch (this.store.aggregateSelected) {
          case 'total':
            // Summarize
            let summaryArr = this.store.summarizeRows(mundialData, dimsArr, metricSet)

            // Sanitize Metrics
            tableRows = this.store.sanitizeMetrics(summaryArr, metricSet, true)

            if (!(this.store.ogTableColumns && this.store.ogPropsToSearch)) {
              if (!(this.store.ogTableColumns)) this.store.ogTableColumns = {}
              if (!(this.store.ogPropsToSearch)) this.store.ogPropsToSearch = {}

              this.store.ogTableColumns[TABLE] = JSON.parse(JSON.stringify(this.store.tableColumns[TABLE]))
              this.store.ogPropsToSearch[TABLE] = JSON.parse(JSON.stringify(this.store.propsToSearch[TABLE]))
            }

            this.store.tableColumns[TABLE] = JSON.parse(JSON.stringify(this.store.ogTableColumns[TABLE])).slice(1)
            this.store.propsToSearch[TABLE] = JSON.parse(JSON.stringify(this.store.ogPropsToSearch[TABLE])).slice(1)
            break;
          case 'daily':
            // Sanitize Metrics
            tableRows = this.store.sanitizeMetrics(mundialData, metricSet, false)

            // TEMP (can remove all)
            if (!(this.store.ogTableColumns && this.store.ogPropsToSearch)) {
              if (!(this.store.ogTableColumns)) this.store.ogTableColumns = {}
              if (!(this.store.ogPropsToSearch)) this.store.ogPropsToSearch = {}

              this.store.ogTableColumns[TABLE] = JSON.parse(JSON.stringify(this.store.tableColumns[TABLE]))
              this.store.ogPropsToSearch[TABLE] = JSON.parse(JSON.stringify(this.store.propsToSearch[TABLE]))
            }

            // TEMP (remove parse-stringify)
            this.store.tableColumns[TABLE] = JSON.parse(JSON.stringify(this.store.ogTableColumns[TABLE]))
            this.store.propsToSearch[TABLE] = JSON.parse(JSON.stringify(this.store.ogPropsToSearch[TABLE]))
            break;
          default:
            tableRows = []
        }

        // Sync to export
        this.store.exportRows[TABLE] = tableRows
        return tableRows
      },

      tableData () {
        // Toggle Aggregation
        let cleanRows = [];
        if (this.store.mundialData.length) {
          cleanRows = this.generateSummary
        }
        return cleanRows;
      },
    },
    data () {
      return {
        store,
        MUNDIAL_PLATFORM,
        pagination: {
          perPage: 25,
          currentPage: 1,
          perPageOptions: [25, 50, 100, 200],
          total: 0
        },
        searchQuery: '',
        TABLE,
      }
    },
    mounted () {
      // Integrate Roles
      // Sync export w/ filtered table
      if (this.store.mmData.user.is_superuser) {
        this.store.tableColumns = adminCols
        this.store.propsToSearch = adminSearch
      } else if (this.store.mmData.user.is_staff) {
        this.store.tableColumns = mundialCols
        this.store.propsToSearch = adminSearch
      } else {
	this.store.tableColumns = clientCols
        this.store.propsToSearch = clientSearch
      }

      // Generate naming dictionary
      //this.store.colsDict = {}
      const tableColumns = this.store.tableColumns;
      for (let c = 0; c < tableColumns.length; c++) {
        const tmpCol = tableColumns[c];
        this.store.colsDict[tmpCol.prop] = tmpCol.label
      }

      // Generate width dictionary
      //console.log({store:this.store})
    },
  }
</script>
<style>
  .mmWarning {
    color: #ef8157;
  }
  #exportMM {
    color: black;
  }
  .search-mm {
    margin-top: 25px;
  }
  .body-mm {
    padding-top: 0px !important;
  }
</style>
<style lang="scss">
  .el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
  }
</style>
