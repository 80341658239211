<template>
  <div>
    <ValidationObserver v-slot="{ invalid }" ref="observer" tag="form" @submit.prevent="getPlacementGroupMaps()">
    <div v-if="store.mmData" class="row mundialBorders mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-6">
        <div class="form-group">
          <ValidationProvider
              name="campaign"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Campaign" v-if="store.mmData"
                name="campaign"
                autocomplete="off"
                v-model="store.campaignSelected"
                :error="failed ? 'Campaign is required' : null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default"
                       size="large"
                       v-model="store.campaignSelected"
                       placeholder="Required *">
                  <el-option v-for="option in store.mmData.campaign_options.filter(option => mappedCampaignSet.has(option.value) && option.archive_flag !== 1 && option.ignore_flag !== 1)"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-4 mundial-vcenter">
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-2 mundial-costmap-vcenter">
        <button @click.prevent="getPlacementGroupMaps()" class="btn btn-success btn-fill btn-wd btn-mundial-success" :disabled="invalid">Retrieve</button>
      </div>
    </div>
    </ValidationObserver>

    <!-- Placement Group Map table -->
    <placement-group-map-tables v-if="store.mmData"
      :renderFlag="renderFlag"
      :campaignPlacementGroupList="campaignPlacementGroupList" 
      :ogCampaignPlacementGroupList="ogCampaignPlacementGroupList"
      :resetFilters="resetFilters">
    </placement-group-map-tables>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import PlacementGroupMapTables from 'src/components/Dashboard/Views/Tables/PlacementGroupMapTables.vue'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";
  
  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  //
  // Set Dashboard Defaults
  //
  store.campaignSelected = ''

  export default {
    components: {
      PlacementGroupMapTables,
      Modal,
      [Button.name]: Button,
    },
    data () {
      return {
        campaignPlacementGroupList: [],
        ogCampaignPlacementGroupList: [],
        store,
        renderFlag: false,
      }
    },
    methods: {
      resetFilters () {
        // Update global
        store.campaignSelected = ''

        // Update local
        this.renderFlag = false;
      },
      async getPlacementGroupMaps() {
        this.renderFlag = false;

        console.log('SUCCESS')

        // Mapping variables
        const campaignSelected = store.campaignSelected;

        // Retrieve DB data & exclude unmapped entries (campaign_placement_group_list)
        const campaignPlacementMaps = store.mmData.campaign_placement_group_list,
        boolCheck = await store.getMundialCampaignPlacementGroupMap({campaignSelected});
        if (boolCheck) {
          this.campaignPlacementGroupList = JSON.parse(JSON.stringify(store.mmData.unmapped_placement_list))
          this.ogCampaignPlacementGroupList = JSON.parse(JSON.stringify(this.campaignPlacementGroupList))
        }

        // Update render flag
        this.renderFlag = true;
      },
    },
    computed: {
      mappedCampaignSet () {
        const campaignIDSet = !store.mmData.mapped_campaign_list ? new Set([]) : Array.isArray(store.mmData.mapped_campaign_list) ? new Set(store.mmData.mapped_campaign_list) : new Set([parseInt(store.mmData.mapped_campaign_list)]);

        console.log({campaignIDSet})

        return campaignIDSet
      },
    },
    async mounted () {
      //console.log({store})
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false

      //
      // Set Dashboard Defaults
      //
      store.campaignSelected = ''
    }
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
</style>
