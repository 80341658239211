<template>
  <div>
    <!-- Campaign table -->
    <campaign-tables v-if="store.mmData" :toggleCreate="toggleCreate" :toggleEdit="toggleEdit" :setEditValue="setEditValue" ></campaign-tables>

    <!-- create Campaign modal -->
    <modal :show.sync="modals.createModal"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Create Campaign</h3>
      <create-campaign-forms class="mm-modal mm-create" :toggleCreate="toggleCreate" ></create-campaign-forms>
    </modal>

    <!-- edit Campaign modal -->
    <modal :show.sync="modals.editModal"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Edit Campaign</h3>
      <edit-campaign-forms class="mm-modal mm-create" :toggleEdit="toggleEdit" :editValue="editValue" :OGValue="OGValue"></edit-campaign-forms>
    </modal>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import CampaignTables from 'src/components/Dashboard/Views/Tables/CampaignTables.vue'
  import CreateCampaignForms from 'src/components/Dashboard/Views/Forms/CreateCampaignForms.vue'
  import EditCampaignForms from 'src/components/Dashboard/Views/Forms/EditCampaignForms.vue'

  export default {
    components: {
      CampaignTables,
      CreateCampaignForms,
      EditCampaignForms,
      Modal,
      [Button.name]: Button,
    },
    data () {
      return {
        store,
        modals: {
          createModal: false,
          editModal: false,
        },
        editValue: {
          common_name: '',
          start_date: '',
          end_date: '',
        },
        OGValue: {
          common_name: '',
          start_date: '',
          end_date: '',
        },
      }
    },
    methods: {
      setEditValue(obj) {
        console.log(obj)

        const keyArr = Object.keys(obj),
        keyLen = keyArr.length;

        for (let k = 0; k < keyLen; k++) {
          const tmpKey = keyArr[k];

          switch(tmpKey) {
            case 'start_date':
              const tmpStart = new Date(obj[tmpKey]),
              adjStart = new Date(tmpStart.getTime() + 3600 * 1000 * 24 * 1).toISOString().split('T')[0]

              console.log({tmpStart, adjStart})

              this.editValue[tmpKey] = adjStart
              this.OGValue[tmpKey] = adjStart
              break;
            case 'end_date':
              const tmpEnd = new Date(obj[tmpKey]),
              adjEnd = new Date(tmpEnd.getTime() + 3600 * 1000 * 24 * 1).toISOString().split('T')[0]

              console.log({tmpEnd, adjEnd})

              this.editValue[tmpKey] = adjEnd
              this.OGValue[tmpKey] = adjEnd
              break;
            default:
              this.editValue[tmpKey] = obj[tmpKey]
              this.OGValue[tmpKey] = obj[tmpKey]
          }
        }
      },
      toggleCreate() {
        this.modals.createModal = !this.modals.createModal
      },
      toggleEdit() {
        this.modals.editModal = !this.modals.editModal
      },
    },
    async mounted () {
      //await store.initMundial()

      //console.log({store})
    }
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
</style>
