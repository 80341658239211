<template>
    <div>
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(editRow)" autocomplete="off">

        <div class="row">
          <div class="col-md-2">
          </div>
          <div class="col-md-8">
            <ValidationProvider
              name="placement"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'Placement Name must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="placement"
                      label="Placement"
                      placeholder="Placement Name"
                      v-model="editValue.common_name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-md-2">
          </div>
        </div>


        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd">
            Edit
          </button>
          <button type="button" class="btn btn-default btn-fill btn-wd" @click="toggleEditModal">
            Cancel
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  export default {
    props: ['toggleEdit','editValue'],
    data () {
      return {
        store,
      }
    },
    computed: {
      tableColumns () {
        return store.tableColumns
      },
    },
    methods: {
      toggleEditModal() {
        this.editValue.common_name = ''
        this.toggleEdit()
      },
      async editRow () {
        const userDetails = {};
        userDetails.common_name = this.editValue.common_name
        userDetails.id = this.editValue.id

        //console.log('Your data: ' + JSON.stringify(userDetails))

        const boolCheck = await store.updateMundialPlacement(userDetails)
        if (boolCheck === 'EXISTING_UNIQUE') {
            alert('PLACEMENT already exists!')
        } else if (boolCheck) {
            this.toggleEdit()
        } else {
            alert('PLACEMENT is incorrect!')
        }
      },
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
    },
  }

</script>
<style>

</style>
