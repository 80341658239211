<template>
    <div>
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createRow)" autocomplete="off">

        <div class="row">
          <div class="col-md-2">
          </div>
          <div class="col-md-8">
            <ValidationProvider
              name="placement"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'Placement Name must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="placement"
                      label="Name"
                      placeholder="Placement Name"
                      v-model="details.common_name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-md-2">
          </div>
        </div>


        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd">
            Create
          </button>
          <button type="button" class="btn btn-default btn-fill btn-wd" @click="toggleCreateModal">
            Cancel
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  export default {
    props: ['toggleCreate'],
    data () {
      return {
        store,
        details: {
          placement_name: '',
          common_name: '',
          placement_id: 9876543210,
          ignore_flag: 0,
          cdate: (new Date()).toISOString().split('T')[0],
          udate: (new Date()).toISOString().split('T')[0],
        }
      }
    },
    methods: {
      generateRandom10Digit() {
        const min = 2000000001; // Minimum 10-digit number greater than 2,000,000,000
        const max = 9999999999; // Maximum 10-digit number
        return Math.floor(Math.random() * (max - min + 1)) + min;
      },      
      toggleCreateModal() {
        this.details.common_name = ''
        this.toggleCreate()
      },
      async createRow () {
        const userDetails = this.details;
        userDetails['placement_name'] = userDetails['common_name']
        userDetails['placement_id'] = this.generateRandom10Digit()

        //console.log('Your data: ' + JSON.stringify(userDetails))

        const boolCheck = await this.store.createMundialPlacement(userDetails)
        if (boolCheck === 'EXISTING_UNIQUE') {
            alert('PLACEMENT already exists!')
        } else if (boolCheck) {
            // Placements
            const placement_options = store.mmData.placement_options, rowLen = placement_options.length;

            for (let r = 0; r < rowLen; r++) {
              const tmpRow = placement_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

              store.idPlacementDict[tmpId] = tmpName
            }

            this.toggleCreate()
        } else {
            alert('PLACEMENT is incorrect!')
        }
      },
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
    },
  }

</script>
<style>

</style>
