<template>
  <div v-if="store.mmData && !store.mmData.user.display_tc && renderFlag" class="row">
    <div class="col-md-12">
      <h4 class="title">Segment Insights Report</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">
          utilities table
          <button id="exportMM"
            class="btn btn-badge btn-round btn-warning pull-right"
            @click.prevent="() => store.exportMundial(searchQuery, TABLE)"
            v-if="store.mmCustomCSVHeaders.hasOwnProperty(TABLE)">
              export csv <i class="nc-icon nc-cloud-download-93"></i>
          </button>          
        </div>
      </div>
      <div class="card-body row body-mm">
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped"
                    :data="queriedData"
                    border
                    style="width: 100%">


            <el-table-column v-for="column in tableColumns"
                             sortable
                             :align="column.align"
                             :key="column.label"
                             :min-width="store.colWidthDict[column.prop]"
                             :prop="column.prop"
                             :label="column.label">
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import { store } from 'src/store'
  import { segmentInsightsCols, segmentInsightsSearch } from './MundialColumns'
  import users from './users'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default{
    props: ['renderFlag','segmentInsightsList','ogSegmentInsightsList','resetFilters', "TABLE"],
    components: {
      PPagination
    },
    methods: {
    },
    computed: {
      tableColumns () {
        return segmentInsightsCols
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        const propsToSearch = segmentInsightsSearch,
        lowerSearchQuery = this.searchQuery.toLowerCase()
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of propsToSearch) {
              let rowValue = row[key].toString().toLowerCase()
              if (rowValue.includes && rowValue.includes(lowerSearchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      generateSummary () {
        const metricArr = ['segment_id','placement_id','demo_id',],
        metricSet = new Set(metricArr);
        let mundialData = JSON.parse(JSON.stringify(this.segmentInsightsList ? this.segmentInsightsList : [])), tableRows = [];

        // Sanitize & Standardize
        mundialData = mundialData.length ? store.sanitizeMetrics(mundialData, metricSet, false) : []
        tableRows = mundialData.length ? mundialData : []

        // Sync to export
        store.exportRows[this.TABLE] = tableRows
        return tableRows
      },
      tableData () {
        // Toggle Aggregation
        let cleanRows = [];
        if (store.mmData) {
          cleanRows = this.generateSummary
        }
        
        //console.log({cleanRows})
        
        return cleanRows;
      },
    },
    data () {
      return {
        store,
        MUNDIAL_PLATFORM,
        pagination: {
          perPage: 250,
          currentPage: 1,
          perPageOptions: [25, 50, 100, 200],
          total: 0
        },
        searchQuery: '',
        mapDict: {},
      }
    },
    mounted () {
      // Generate naming dictionary
      const tableColumns = segmentInsightsCols;
      for (let c = 0; c < tableColumns.length; c++) {
        const tmpCol = tableColumns[c];
        store.colsDict[tmpCol.prop] = tmpCol.label
      }

      // Log to console
      console.log({store})

      // Reset Dashboard
      this.resetFilters()
    },
  }
</script>
<style>
  .mmWarning {
    color: #ef8157;
  }
  #exportMM {
    color: black;
  }
  .search-mm {
    margin-top: 25px;
  }
  .body-mm {
    padding-top: 0px !important;
  }
  .mundial-vcenter > .btn-mundial-reset {
    
  }
</style>
<style lang="scss">
  .el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
  }
</style>
