<template>
  <div>
    <!-- Mapped Placement table -->
    <mapped-placement-tables v-if="store.mmData" :toggleCreate="toggleCreate" :toggleEdit="toggleEdit" :setEditValue="setEditValue" ></mapped-placement-tables>

    <!-- create Placement modal -->
    <modal :show.sync="modals.createModal"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Create Placement</h3>
      <create-placement-forms class="mm-modal mm-create" :toggleCreate="toggleCreate" ></create-placement-forms>
    </modal>

    <!-- edit Placement modal -->
    <modal :show.sync="modals.editModal"
           footerClasses="justify-content-center"
           type="notice">
      <h3 slot="header" class="modal-title">Edit Placement</h3>
      <edit-placement-forms class="mm-modal mm-create" :toggleEdit="toggleEdit" :editValue="editValue" ></edit-placement-forms>
    </modal>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import MappedPlacementTables from 'src/components/Dashboard/Views/Tables/MappedPlacementTables.vue'
  import CreatePlacementForms from 'src/components/Dashboard/Views/Forms/CreatePlacementForms.vue'
  import EditPlacementForms from 'src/components/Dashboard/Views/Forms/EditPlacementForms.vue'

  export default {
    components: {
      MappedPlacementTables,
      CreatePlacementForms,
      EditPlacementForms,
      Modal,
      [Button.name]: Button,
    },
    data () {
      return {
        store,
        modals: {
          createModal: false,
          editModal: false,
        },
        editValue: {
          common_name: '',
        },
      }
    },
    methods: {
      setEditValue(obj) {
        console.log(obj)

        const keyArr = Object.keys(obj),
        keyLen = keyArr.length;

        for (let k = 0; k < keyLen; k++) {
          const tmpKey = keyArr[k];

          this.editValue[tmpKey] = obj[tmpKey]
        }
      },
      toggleCreate() {
        this.modals.createModal = !this.modals.createModal
      },
      toggleEdit() {
        this.modals.editModal = !this.modals.editModal
      },
    },
    async mounted () {
      //await store.initMundial()

      //console.log({store})
    }
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
</style>
