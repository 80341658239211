<template>
    <div>
      <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createRow)" autocomplete="off">

        <div class="row">
          <div class="col-md-2">
          </div>
          <div class="col-md-8">
            <ValidationProvider
              name="campaign"
              rules="min:2"
              v-slot="{ passed, failed }"
            >
              <fg-input type="text"
                      autocomplete="off"
                      :error="failed ? 'Campaign Name must be at least 2 characters': null"
                      :hasSuccess="passed"
                      name="campaign"
                      label="Name"
                      placeholder="Campaign Name"
                      v-model="details.common_name">
              </fg-input>
            </ValidationProvider>
          </div>
          <div class="col-md-2">
          </div>
        </div>


        <div class="text-center">
          <button type="submit" class="btn btn-info btn-fill btn-wd">
            Create
          </button>
          <button type="button" class="btn btn-default btn-fill btn-wd" @click="toggleCreateModal">
            Cancel
          </button>
        </div>
        <div class="clearfix"></div>
      </form>
      </ValidationObserver>
  </div>
</template>
<script>
  import { store } from 'src/store'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);

  export default {
    props: ['toggleCreate'],
    data () {
      return {
        store,
        details: {
          campaign_name: '',
          common_name: '',
          campaign_id: 87654321,
          archive_flag: 0,
          ignore_flag: 0,
          start_date: (new Date()).toISOString().split('T')[0],
          end_date: (new Date()).toISOString().split('T')[0],
          cdate: (new Date()).toISOString().split('T')[0],
          udate: (new Date()).toISOString().split('T')[0],
        }
      }
    },
    methods: {
      generateRandom8Digit() {
        const min = 60000001; // Minimum 8-digit number greater than 60,000,000
        const max = 99999999; // Maximum 8-digit number
        return Math.floor(Math.random() * (max - min + 1)) + min;
      },
      toggleCreateModal() {
        this.details.common_name = ''
        this.toggleCreate()
      },
      async createRow () {
        const userDetails = this.details;
        userDetails['campaign_name'] = userDetails['common_name']
        userDetails['campaign_id'] = this.generateRandom8Digit()

        //console.log('Your data: ' + JSON.stringify(userDetails))

        const boolCheck = await this.store.createMundialCampaign(userDetails)
        if (boolCheck === 'EXISTING_UNIQUE') {
            alert('CAMPAIGN already exists!')
        } else if (boolCheck) {
            // Campaigns
            const campaign_options = store.mmData.campaign_options, rowLen = campaign_options.length;

            for (let r = 0; r < rowLen; r++) {
              const tmpRow = campaign_options[r], tmpId = tmpRow.value, tmpName = tmpRow.label;

              store.idCampaignDict[tmpId] = tmpName
            }

            this.toggleCreate()
        } else {
            alert('CAMPAIGN is incorrect!')
        }
      },
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false
    },
  }

</script>
<style>

</style>
